import React from "react";
import classes from "./BurgerControl.module.css";
import { CgMathPlus, CgMathMinus } from "react-icons/cg";

const BurgerControl = (props) => {
	return (
		<div className={classes.Control}>
			<div className={classes.Name}>{props.ingredientName}</div>

			<div className={classes.Amount}>
				<button onClick={props.onRemove}>
					<CgMathMinus />
				</button>
				<span>{props.amount}</span>
				<button onClick={props.onAdd}>
					<CgMathPlus />
				</button>
			</div>

			<div className={classes.Price}>${props.price}/unit</div>

			{/* <div className={classes.Total}>${(props.price * props.amount).toFixed(2)}</div> */}
		</div>
	);
};

export default BurgerControl;
