import Bacon from "../../assets/images/Bacon.png";
import Beef from "../../assets/images/Beef.png";
import BellPepper from "../../assets/images/BellPepper.png";
import BreadBottom from "../../assets/images/BreadBottom.png";
import BreadTop from "../../assets/images/BreadTop.png";
import Cheese from "../../assets/images/Cheese.png";
import Chicken from "../../assets/images/Chicken.png";
import Egg from "../../assets/images/Egg.png";
import Herb from "../../assets/images/Herb.png";
import Ketchup from "../../assets/images/Ketchup.png";
import Lettuce from "../../assets/images/Lettuce.png";
import Mayonnaise from "../../assets/images/Mayonnaise.png";
import Mushroom from "../../assets/images/Mushroom.png";
import Mustard from "../../assets/images/Mustard.png";
import Onion from "../../assets/images/Onion.png";
import Pickle from "../../assets/images/Pickle.png";
import Pork from "../../assets/images/Pork.png";
import Sausage from "../../assets/images/Sausage.png";
import Tomato from "../../assets/images/Tomato.png";

export const ingredients = [
	{ name: "Bacon", type: "Meat", imgUrl: Bacon, price: 1.9, thickness: 20 },
	{ name: "Beef", type: "Meat", imgUrl: Beef, price: 4.9, thickness: 60 },
	{ name: "Bell Pepper", type: "Vegetable", imgUrl: BellPepper, price: 0.9, thickness: 10 },
	{ name: "Bread Bottom", type: "Bread", imgUrl: BreadBottom, price: 0, thickness: 40 },
	{ name: "Bread Top", type: "Bread", imgUrl: BreadTop, price: 0, thickness: 20 },
	{ name: "Cheese", type: "Side Dish", imgUrl: Cheese, price: 1.9, thickness: 10 },
	{ name: "Chicken", type: "Meat", imgUrl: Chicken, price: 2.9, thickness: 30 },
	{ name: "Egg", type: "Side Dish", imgUrl: Egg, price: 1.9, thickness: 20 },
	{ name: "Herb", type: "Vegetable", imgUrl: Herb, price: 0.4, thickness: 10 },
	{ name: "Ketchup", type: "Sauce", imgUrl: Ketchup, price: 0.2, thickness: 0 },
	{ name: "Lettuce", type: "Vegetable", imgUrl: Lettuce, price: 0.4, thickness: 10 },
	{ name: "Mayonnaise", type: "Sauce", imgUrl: Mayonnaise, price: 0.2, thickness: 0 },
	{ name: "Mushroom", type: "Vegetable", imgUrl: Mushroom, price: 1.9, thickness: 5 },
	{ name: "Mustard", type: "Sauce", imgUrl: Mustard, price: 0.2, thickness: 0 },
	{ name: "Onion", type: "Vegetable", imgUrl: Onion, price: 0.4, thickness: 10 },
	{ name: "Pickle", type: "Vegetable", imgUrl: Pickle, price: 0.3, thickness: 10 },
	{ name: "Pork", type: "Meat", imgUrl: Pork, price: 3.9, thickness: 60 },
	{ name: "Sausage", type: "Side Dish", imgUrl: Sausage, price: 1.9, thickness: 10 },
	{ name: "Tomato", type: "Vegetable", imgUrl: Tomato, price: 0.3, thickness: 10 },
];

export default ingredients;
