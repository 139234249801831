import React from "react";
import classes from "./Buger.module.css";
import burgerIngredientList from "../IngredientList";
import BurgerIngredient from "./BurgerIngredient/BurgerIngredient";

const Burger = (props) => {
	let currentThickness = 0;
	let ingredients = props.ingredients.map((ingredient, index) => {
		const ingr = <BurgerIngredient type={ingredient} key={index} order={index} fromBottom={currentThickness} />;

		currentThickness += burgerIngredientList.find((ingr) => ingr.name === ingredient).thickness;

		return ingr;
	});

	return (
		<div className={classes["burger-wrapper"]}>
			<div className={classes.Burger} style={{ height: 230 + currentThickness + "px" }}>
				{ingredients}
			</div>
		</div>
	);
};

export default Burger;
